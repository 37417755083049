export const homePath = '/';
export const trackingLinkPath =
  'https://rastreamento.correios.com.br/app/index.php?objetos';
export const orderListPath = '/pedidos';
export const exchangesPath = '/pedidos/devolucoes';
export const massiveActionsPath = '/pedidos/acoes-massivas';
export const shippingManagementPath = '/pedidos/gestao-de-envios';
export const orderIdPath = '/pedidos/:id';
export const catalogPath = '/catalogo';
export const userProfile = '/configuracoes/meu-perfil';
export const userStore = '/configuracoes/minha-loja';
export const RegisteredUsers = '/configuracoes/usuarios-cadastrados';
export const loginPath = '/login';
export const forgetPasswordPath = '/recuperar-senha';
export const firstAcessPath = '/primeiro-acesso';
export const orderReports = '/relatorios-pedidos';
export const productReportsPath = '/relatorios-catalogo';
export const completedReportsPath = '/relatorios-reputacao';
export const faqPath = '/central-de-ajuda/faq/seller-index';
export const generateReportsPath = '/gerar-relatorios-reputacao';
export const financialPath = '/relatorio-financeiro';
export const generalFaqPath = '/central-de-ajuda/faq';
export const exchangeAndReturnFaq =
  'https://www.centauro.com.br/sc/politica-de-troca-no-site';
export const financialFaqPath = '/central-de-ajuda/faq/financeiro';
export const orderFaqPath = '/central-de-ajuda/faq/pedidos';
export const catalogFaqPath = '/central-de-ajuda/faq/catalogo';
export const sacFaqPath = '/central-de-ajuda/faq/sac';
export const centauroEnviosFaqPath = '/central-de-ajuda/faq/centauro-envios';
export const centauroTeamFaqPath = '/central-de-ajuda/faq/comunicacao-centauro';
export const storeIntegrationFaqPath = '/central-de-ajuda/faq/integracao';
export const adminUserPath = '/cadastro-usuario';
export const tutorialsPath = '/central-de-ajuda/tutoriais';

export const urlMap = {
  [exchangesPath]: 'Lista de devoluções',
  [generateReportsPath]: 'Gerar relatórios',
  [orderListPath]: 'Pedidos',
  [catalogPath]: 'Catálogo',
  [loginPath]: 'Login',
  [forgetPasswordPath]: 'Recuperar senha',
  [firstAcessPath]: 'Primeiro acesso',
  [orderReports]: 'Relatório de pedidos',
  [productReportsPath]: 'Relatório de produtos',
  [faqPath]: 'FAQ Seller Index',
  [completedReportsPath]: 'Relatórios gerados',
  [financialPath]: 'Financeiro',
  [generalFaqPath]: 'FAQ geral',
  [financialFaqPath]: 'FAQ Financeiro',
  [orderFaqPath]: 'FAQ Pedidos',
  [catalogFaqPath]: 'FAQ Catálogo',
  [sacFaqPath]: 'SAC',
  [centauroEnviosFaqPath]: 'FAQ Centauro Envios',
  [centauroTeamFaqPath]: 'FAQ Time Centauro',
  [storeIntegrationFaqPath]: 'FAQ Integração',
  [adminUserPath]: 'Cadastro de usuário',
  [tutorialsPath]: 'Tutoriais',
  [massiveActionsPath]: 'Ações massivas',
  [shippingManagementPath]: 'Gestão de envios',
};
