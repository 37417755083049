import * as Sentry from '@sentry/nextjs';
import axios from 'axios';
import { JWT } from 'next-auth/jwt';
import { signIn } from 'next-auth/react';
import { KeycloakGroupType } from '@/common/types/keycloak';
import { getJson } from './httpClient/httpClientBff';

export const webapiUrl = process.env.NEXT_PUBLIC_PORTAL_SELLER_WEBAPI;
const bffKeycloakTokenCheck = `${webapiUrl}v2/users/check/token`;
const bffKeycloaGroupUrl = `${webapiUrl}v1/groups`;

export const getKeycloakUserGroups = async (
  name = '',
): Promise<KeycloakGroupType[]> => {
  const filter = name !== '' ? `/search?term=${name}&limit=20` : '';
  const url = `${bffKeycloaGroupUrl}${filter}`;
  const request = await getJson(url);

  return request.data?.items;
};

export const checkForKeycloakTokenStatus = async () => {
  const request = await getJson(bffKeycloakTokenCheck);

  return request;
};

// we got this function from the social commerce project
export const refreshAccessToken = async (token: JWT) => {
  try {
    const details = {
      client_id: process.env.NEXT_PUBLIC_KEYCLOAK_RESOURCE || '',
      client_secret: process.env.NEXT_PUBLIC_KEYCLOAK_SECRET || '',
      grant_type: ['refresh_token'],
      refresh_token: token.refreshToken,
    };
    const formBody: string[] = [];
    Object.entries(details).forEach(
      ([key, value]: [string, string | string[]]) => {
        const encodedKey = encodeURIComponent(key);
        const encodedValue = encodeURIComponent(value as string);
        formBody.push(encodedKey + '=' + encodedValue);
      },
    );
    const formData = formBody.join('&');
    const url = `${process.env.NEXT_PUBLIC_KEYCLOAK_BASE_URL}/token`;
    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    });
    const refreshedTokens = response.data;
    if (response.status !== 200) throw refreshedTokens;
    return {
      ...token,
      accessToken: refreshedTokens.access_token as string,
      accessTokenExpiresIn: Date.now() + refreshedTokens.expires_in * 1000,
      refreshToken: refreshedTokens.refresh_token ?? token.refreshToken,
      refreshTokenExpiresIn:
        Date.now() + refreshedTokens.refresh_expires_in * 1000,
    };
  } catch {
    return {
      ...token,
      error: 'RefreshTokenError',
    };
  }
};

export const getActiveGroup = async (
  token: string,
  sessionGroup: KeycloakGroupType | undefined,
) => {
  if (sessionGroup) return sessionGroup;
  if (!token) {
    Sentry.captureException(`getActiveGroup: token isn't defined`);
    throw new Error(`getActiveGroup: token isn't defined`);
  }

  const request = await axios.get(bffKeycloaGroupUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (request.status === 401) return signIn('keycloak');

  return request.data.items[0];
};
