import { useState } from 'react';
import SellerSelectModal from '../components/SellerSelectModal/SellerSelectModal';

const useSellerSelectModal = () => {
  const [sellerSelectModalOpen, setSellerSelectModalOpen] = useState(false);

  const toggleSellerModal = () => {
    setSellerSelectModalOpen(!sellerSelectModalOpen);
  };

  return {
    toggleSellerModal,
    SellerSelectModalComponent: (
      <SellerSelectModal
        open={sellerSelectModalOpen}
        toggleSellerModal={toggleSellerModal}
      />
    ),
  };
};

export default useSellerSelectModal;
