import React from 'react';

const NotAuthorized = () => {
  return (
    <h3>
      Seu usuário não possui permissão para acessar essa rota. Contate o
      administrador ou faça login novamente.
    </h3>
  );
};

export default NotAuthorized;
