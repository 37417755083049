import { Dialog } from '@material-ui/core';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { sendModalViewEvent } from '@/common/utils/tracking/trackingEvents';
import { DesktopView } from '../DesktopView';
import {
  StyledCloseButton,
  StyledCloseIcon,
  StyledRoot,
  StyledDialogTitle,
  ModalHeader,
  StyledDialogContent,
  ButtonOpenModal,
  StyledDialogActions,
  ButtonOpenModalLabel,
} from './Modal.styled';
import { ModalType } from './Modal.types';

const Modal = ({
  title,
  modalTitle,
  modalIcon,
  openButton,
  customContent,
  btnOkAction,
  beforeClose,
  forceClose = false,
  persists = false,
  error,
  justifyContentButton = 'flex-start',
  hasHeight = false,
  borderRadius,
  isOpen = true,
}: ModalType): JSX.Element => {
  const router = useRouter();
  const [open, setOpen] = useState(isOpen);

  const handleVisibility = (): void => {
    if (persists) return setOpen(true);

    if (beforeClose) {
      beforeClose();
    }

    return setOpen(!open);
  };

  useEffect(() => {
    if (forceClose) {
      setOpen(false);
    }
  }, [forceClose]);

  useEffect(() => {
    if (isOpen) {
      sendModalViewEvent(title || modalTitle);
    }
    setOpen(isOpen);
  }, [router.pathname, isOpen]);

  const IconButton = openButton?.icon;

  return (
    <>
      {openButton && (
        <ButtonOpenModal
          onClick={handleVisibility}
          data-testid="button-modal-notice"
          aria-controls="simple-menu"
          aria-haspopup="true"
        >
          <DesktopView>
            <ButtonOpenModalLabel>{title}</ButtonOpenModalLabel>
          </DesktopView>
          {IconButton && <IconButton />}
        </ButtonOpenModal>
      )}

      <Dialog
        data-testid="default-modal"
        maxWidth="lg"
        open={open}
        disableEnforceFocus
      >
        <StyledRoot borderRadius={borderRadius}>
          <ModalHeader>
            <StyledDialogTitle>
              {modalIcon}
              {modalTitle}
            </StyledDialogTitle>

            {!persists && (
              <StyledCloseButton onClick={handleVisibility}>
                <StyledCloseIcon />
              </StyledCloseButton>
            )}
          </ModalHeader>

          <StyledDialogContent hasHeight={hasHeight}>
            {customContent}
          </StyledDialogContent>

          <StyledDialogActions content={justifyContentButton}>
            {btnOkAction}
          </StyledDialogActions>
          {error}
        </StyledRoot>
      </Dialog>
    </>
  );
};

export default Modal;
