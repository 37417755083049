import { observable, action, makeObservable } from 'mobx';

class QuestionsAndAnswersStore {
  questions = {
    links: [
      {
        rel: 'next',
        href: 'http://trustvox.com.br/api/stores/1/questions?page=3',
      },
      {
        rel: 'prev',
        href: 'http://trustvox.com.br/api/stores/1/questions?page=1',
      },
      {
        rel: 'self',
        href: 'http://trustvox.com.br/api/stores/1/questions?page=2',
      },
    ],
    items: [
      {
        id: 1,
        text: 'Estou fazendo natação, posso utilizar meia?',
        created_at: '2015-03-19T18:15:25-03:00',
        updated_at: '2015-03-19T18:15:25-03:00',
        visible: true,
        state: 'replied',
        consumer: {
          email: 'tiago@trustvox.com',
          first_name: 'Tiago',
          last_name: 'Lima',
        },
        answer: {
          id: 1,
          text: 'Não utilize este tênis para fazer natação. Obrigado.',
          created_at: '2015-03-19T18:15:25-03:00',
          links: [
            { rel: 'self', href: 'http://trustvox.com.br/api/answers/1' },
            { rel: 'question', href: 'http://trustvox.com.br/api/question/1' },
          ],
        },
        product: {
          id: 1,
          name: 'Sapato',
          code: '123',
          links: [
            { rel: 'product_permalink', href: 'http://loja.com.br/products/1' },
            { rel: 'image', href: 'http://trustvox.com.br/images/sapato.jpeg' },
          ],
        },
        links: [
          { rel: 'self', href: 'http://trustvox.com.br/api/questions/1' },
          { rel: 'store_url', href: 'http://trustvox.com.br/api/stores/1' },
        ],
      },
      {
        id: 2,
        text: 'Este tênis pode ser utilizado para corrida?',
        created_at: '2015-03-20T18:15:25-03:00',
        updated_at: '2015-03-20T18:15:25-03:00',
        visible: true,
        state: 'replied',
        consumer: {
          email: 'juliana@example.com',
          first_name: 'Juliana',
          last_name: 'Lucena',
        },
        answer: {
          id: 2,
          text: 'Não. Este tênis é um modelo casual. Obrigado.',
          created_at: '2015-03-21T18:15:25-03:00',
          links: [
            { rel: 'self', href: 'http://trustvox.com.br/api/answers/2' },
            { rel: 'question', href: 'http://trustvox.com.br/api/question/1' },
          ],
        },
        product: {
          id: 2,
          name: 'Sapato',
          code: '123',
          links: [
            { rel: 'product_permalink', href: 'http://loja.com.br/products/2' },
            {
              rel: 'image',
              href: 'http://trustvox.com.br/images/brinquedo.jpeg',
            },
          ],
        },
        links: [
          { rel: 'self', href: 'http://trustvox.com.br/api/questions/2' },
          { rel: 'store', href: 'http://trustvox.com.br/api/stores/2' },
        ],
      },
      {
        id: 3,
        text: 'É de couro?',
        created_at: '2015-03-20T18:15:25-03:00',
        updated_at: '2015-03-20T18:15:25-03:00',
        visible: true,
        state: 'replied',
        consumer: {
          email: 'juliana@example.com',
          first_name: 'Juliana',
          last_name: 'Lucena',
        },
        answer: {
          id: 2,
          text: 'Este tênis é de couro verdadeiro. Obrigado, amigo.',
          created_at: '2015-03-21T18:15:25-03:00',
          links: [
            { rel: 'self', href: 'http://trustvox.com.br/api/answers/2' },
            { rel: 'question', href: 'http://trustvox.com.br/api/question/1' },
          ],
        },
        product: {
          id: 2,
          name: 'Sapato',
          code: '123',
          links: [
            { rel: 'product_permalink', href: 'http://loja.com.br/products/2' },
            {
              rel: 'image',
              href: 'http://trustvox.com.br/images/brinquedo.jpeg',
            },
          ],
        },
        links: [
          { rel: 'self', href: 'http://trustvox.com.br/api/questions/2' },
          { rel: 'store', href: 'http://trustvox.com.br/api/stores/2' },
        ],
      },
      {
        id: 4,
        text: 'Tem a cor violeta para este modelo?',
        created_at: '2015-03-20T18:15:25-03:00',
        updated_at: '2015-03-20T18:15:25-03:00',
        visible: true,
        state: 'replied',
        consumer: {
          email: 'juliana@example.com',
          first_name: 'Juliana',
          last_name: 'Lucena',
        },
        answer: {
          id: 2,
          text: 'Apenas a cor preta e branca.',
          created_at: '2015-03-21T18:15:25-03:00',
          links: [
            { rel: 'self', href: 'http://trustvox.com.br/api/answers/2' },
            { rel: 'question', href: 'http://trustvox.com.br/api/question/1' },
          ],
        },
        product: {
          id: 2,
          name: 'Sapato',
          code: '123',
          links: [
            { rel: 'product_permalink', href: 'http://loja.com.br/products/2' },
            {
              rel: 'image',
              href: 'http://trustvox.com.br/images/brinquedo.jpeg',
            },
          ],
        },
        links: [
          { rel: 'self', href: 'http://trustvox.com.br/api/questions/2' },
          { rel: 'store', href: 'http://trustvox.com.br/api/stores/2' },
        ],
      },
    ],
  };

  setQuestions = (response) => {
    this.questions = response;
  };

  constructor() {
    makeObservable(this, {
      questions: observable,
      setQuestions: action,
    });
  }
}

export default QuestionsAndAnswersStore;
