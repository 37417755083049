import { Fragment, ReactNode } from 'react';
import { BREAKPOINTS_ENUM } from '@/common/hooks/useBreakpoint/types';
import { useBreakpoint } from '../../hooks/useBreakpoint/useBreakpoint';

export const DesktopView = ({ children }: { children: ReactNode }) => {
  const size = useBreakpoint();

  const isDesktop =
    size === BREAKPOINTS_ENUM.LG ||
    size === BREAKPOINTS_ENUM.XL ||
    size === BREAKPOINTS_ENUM['2XL'];

  return isDesktop ? <Fragment>{children}</Fragment> : undefined;
};
