import { media } from '@grupo-sbf/motriz-utils';
import styled, { css } from 'styled-components';

export const PageLayout = styled.div`
  display: flex;
  position: relative;
  min-height: 100vh;
`;

export const Container = styled.div<{ expandedMenu: boolean }>`
  ${({ theme, expandedMenu }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: ${theme.color.neutral[200]};
    margin-left: ${expandedMenu ? '240px' : '88px'};
    max-width: 100vw;

    ${media.lessThan('large')} {
      margin-left: ${theme.spacing[0]};
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    flex: 1;
    margin: ${theme.spacing[200]};
    border-radius: ${theme.borderRadius.large};
    padding: ${theme.spacing[200]};
    background-color: ${theme.color.neutral[100]};
    border-radius: ${theme.borderRadius.medium};
  `}
`;
