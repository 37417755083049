import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { checkForKeycloakTokenStatus } from '@/services/keycloakModule';

const useSessionAlive = () => {
  const [isSessionAlive, setIsSessionAlive] = useState<boolean>(false);

  useQuery({
    queryKey: 'keycloak-token-check',
    queryFn: checkForKeycloakTokenStatus,
    onSuccess(data) {
      if (data && (data as AxiosResponse).status === 200) {
        setIsSessionAlive(true);
      }
    },
    refetchOnWindowFocus: true,
  });

  return { isSessionAlive };
};

export default useSessionAlive;
