import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

export const ProgressContainerStyled = styled.div`
  position: relative;
`;

export const ProgressStyled = styled(CircularProgress)`
  position: absolute;
  width: 20px !important;
  height: 20px !important;
  right: 35px;
  top: 8px;
`;
