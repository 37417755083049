import { observable, action, computed, makeObservable } from 'mobx';

class DataTableStore {
  page = 0;

  setPage = (page) => (this.page = page);

  rowsPerPage = 10;

  setRowsPerPage = (rowsPerPage) => (this.rowsPerPage = rowsPerPage);

  filter = '';

  setFilter = (filter) => {
    this.page = 0;
    this.filter = filter;
  };

  sort = 'lastupdate';

  setSort = (sort) => (this.sort = sort);

  sinceDate = '2020-11-01';

  setSinceDate = (dateSelected) => (this.sinceDate = dateSelected);

  isLoading = false;

  setLoading = (loading) => (this.isLoading = loading);

  errorMessage = '';

  setErrorMessage = (errorMessage) => (this.errorMessage = errorMessage);

  constructor() {
    makeObservable(this, {
      page: observable,
      setPage: action,
      rowsPerPage: observable,
      setRowsPerPage: action,
      filter: observable,
      setFilter: action,
      sort: observable,
      setSort: action,
      sinceDate: observable,
      setSinceDate: action,
      isLoading: observable,
      setLoading: action,
      errorMessage: observable,
      setErrorMessage: action,
      hasError: computed,
      infoMessage: observable,
      setInfoMessage: action,
      hasInfoMessage: computed,
    });
  }

  get hasError() {
    return this.errorMessage.length > 0;
  }

  infoMessage = '';

  setInfoMessage = (infoMessage) => (this.infoMessage = infoMessage);

  get hasInfoMessage() {
    return this.infoMessage.length > 0;
  }
}

export default DataTableStore;
