import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import styled, { css } from 'styled-components';
import VideoBox from '@/modules/tutorial/components/VideoBox/VideoBox';
import { NoticeType } from './NoticesModal.types';

type StyleNoticeType = {
  type: NoticeType;
};

export const StyledRoot = styled.div`
  width: 614px;
  height: 423px;
  text-align: center;
  overflow: hidden;

  ${({ theme }) => css`
    background: ${theme.color.neutral[100]};
    box-shadow: ${theme.shadow[400]};
    border-radius: ${theme.borderRadius.large};
    padding: ${theme.spacing[0]};
  `};
`;

export const StyledUserData = styled.p`
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    color: ${theme.color.neutral[100]};
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.fontSize.tiny};
    line-height: ${theme.lineHeight.normal};
  `};
`;

export const TextContent = styled.p`
  text-align: left;
  position: relative;

  ${({ theme }) => css`
    color: ${theme.color.neutral[700]};
    font-size: ${theme.fontSize.tiny};
    line-height: ${theme.lineHeight.normal};
  `};
`;

export const StyledTitle = styled.h1`
  position: relative;
  text-align: left;

  ${({ theme }) => css`
    color: ${theme.color.neutral[700]};
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.fontSize.medium};
    line-height: ${theme.lineHeight.normal};
    border-bottom: 1px solid ${theme.color.neutral[500]};
    padding-left: ${theme.spacing[800]};
  `};
`;

export const StyledCloseButton = styled(IconButton).attrs({
  size: 'large',
})`
  position: unset !important;
  z-index: 10;
`;

export const StyledSubtitle = styled(DialogTitle)`
  text-align: left;
  position: relative;

  ${({ theme }) => css`
    color: ${theme.color.brand.primary};
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.fontSize.small};
    line-height: ${theme.lineHeight.normal};
    margin-bottom: ${theme.spacing[100]};
    padding: ${theme.spacing[0]};
  `};
`;

export const StyledDialogTitle = styled(DialogTitle)<StyleNoticeType>`
  display: flex;
  visibility: ${({ type }) =>
    type === NoticeType.News ? 'hidden' : 'visible'} !important;
  align-items: center;
  text-align: left;

  ${({ theme }) => css`
    color: ${theme.color.neutral[700]};
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.fontSize.medium};
    line-height: ${theme.lineHeight.normal};
  `};
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.color.neutral[400]};
  `};
`;

export const StyledDialogContent = styled(DialogContent)`
  text-align: left;
  position: relative;
  height: 361px;

  ${({ theme }) => css`
    color: ${theme.color.neutral[700]};
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.fontSize.tiny};
    line-height: ${theme.lineHeight.normal};
    padding: ${theme.spacing[300]};
  `};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7 {
    ${({ theme }) => css`
      margin-bottom: ${theme.spacing[200]};
    `};
  }

  p,
  span,
  img,
  image {
    ${({ theme }) => css`
      margin-bottom: ${theme.spacing[100]};
    `};
  }
`;

export const StyledVideoBox = styled(VideoBox)`
  align-items: center !important;
`;
