import { Button } from '@grupo-sbf/motriz-core';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useState, useCallback, useEffect } from 'react';
import Modal from '@/common/components/Modal';
import useCurrentAccount from '@/common/hooks/useCurrentAccount';
import { KeycloakGroupType } from '@/common/types/keycloak';
import SellerSelect from '../SellerSelect';
import {
  ContainerInput,
  ButtonText,
  Input,
  InputSpan,
} from './SellerSelectModal.styled';

const SellerSelectModal = ({
  toggleSellerModal,
  open,
}: {
  toggleSellerModal: () => void;
  open: boolean;
}) => {
  const router = useRouter();
  const { data: session } = useSession();
  const { currentAccount, setCurrentAccountInLocalStorage } =
    useCurrentAccount();
  const { accessToken = '' } = session || {};

  const [selectedGroup, setSelectedGroup] =
    useState<KeycloakGroupType>(currentAccount);
  const title = 'Mudar de seller';

  const handleSelect = useCallback(() => {
    setCurrentAccountInLocalStorage(selectedGroup);
    toggleSellerModal();
    router.reload();
  }, [
    router,
    selectedGroup,
    setCurrentAccountInLocalStorage,
    toggleSellerModal,
  ]);

  const handleSelectValue = (group: KeycloakGroupType) => {
    setSelectedGroup(group);
  };

  useEffect(() => {
    if (currentAccount?.id !== '') {
      handleSelectValue(currentAccount);
    }
  }, [currentAccount]);

  return (
    <>
      {open && (
        <Modal
          modalTitle={title}
          beforeClose={toggleSellerModal}
          customContent={
            <>
              <ContainerInput>
                <Input variant="outlined">
                  <InputSpan>Escolher seller</InputSpan>
                  <SellerSelect
                    token={accessToken}
                    value={selectedGroup}
                    setValue={handleSelectValue}
                    selectedValue={selectedGroup?.codigo}
                  />
                </Input>
              </ContainerInput>
            </>
          }
          btnOkAction={
            <Button
              variant="secondary"
              onClick={handleSelect}
              disabled={!selectedGroup}
            >
              <ButtonText type="title">Selecionar</ButtonText>
            </Button>
          }
          persists={false}
        />
      )}
    </>
  );
};

export default SellerSelectModal;
