import { observable, action, makeObservable } from 'mobx';
import regeneratorRuntime from 'regenerator-runtime'; // eslint-disable-line

class TestStore {
  counter = 1;

  add = () => (this.counter += 1);

  reset = () => (this.counter = 1);

  constructor() {
    makeObservable(this, {
      counter: observable,
      add: action,
      reset: action,
    });
  }
}

export default TestStore;
