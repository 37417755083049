import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';
import { KeycloakGroupType } from '@/common/types/keycloak';
import { getKeycloakUserGroups } from '@/services/keycloakModule';

const setCurrentAccountInLocalStorage = (account: KeycloakGroupType) => {
  if (typeof window === 'undefined' || account?.id === '') return;
  localStorage.setItem('currentAccount', JSON.stringify(account));
};

const localAccountAsString = () => localStorage.getItem('currentAccount') || '';

const useCurrentAccount = () => {
  const { data: session } = useSession();
  const token = session?.accessToken;
  const [currentAccount, setCurrentAccount] = useState<KeycloakGroupType>({
    id: '',
    name: '',
    codigo: '',
    token: '',
    nome: '',
    tipoCentauroEnviosId: -1,
  });

  useEffect(() => {
    if (!token || currentAccount?.id) return;
    (async () => {
      if (!localAccountAsString()) {
        const keycloakUserGroups = await getKeycloakUserGroups('');

        setCurrentAccount(keycloakUserGroups[0]);
        setCurrentAccountInLocalStorage(keycloakUserGroups[0]);
      } else {
        setCurrentAccount(
          JSON.parse(localAccountAsString()) as KeycloakGroupType,
        );
      }
    })();
  }, [currentAccount, token]);

  return { setCurrentAccount, setCurrentAccountInLocalStorage, currentAccount };
};

export default useCurrentAccount;
