import { Typography } from '@grupo-sbf/motriz-core';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';
import { sendVideoComplete } from '@/common/utils/tracking/trackingEvents';
import { Root, Video } from './VideoBox.styled';

export type VideoBoxProps = {
  title: string;
  embeddedId: string;
  large?: boolean;
  autoplay?: boolean;
};

function VideoBox({
  title,
  embeddedId,
  large = false,
  autoplay = false,
}: VideoBoxProps) {
  const { data: session } = useSession();

  const handleStateChange = (event: { data: number }) => {
    sendVideoComplete(
      embeddedId,
      event,
      `${session?.user.name} - ${session?.activeGroup?.name}` || '',
    );
  };

  useEffect(() => {
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.append(tag);
    }

    const onPlayerReady = () => {
      new window.YT.Player(`player-${embeddedId}`, {
        events: {
          onStateChange: handleStateChange,
        },
      });
    };

    if (window.YT && window.YT.Player) {
      return onPlayerReady();
    }

    window.onYouTubeIframeAPIReady = onPlayerReady;
  }, [embeddedId]);

  return (
    <>
      <Root tabIndex={0} large={large}>
        <Video>
          <iframe
            id={`player-${embeddedId}`}
            width={large ? '550' : '275'}
            height={large ? '322' : '161'}
            src={`https://www.youtube.com/embed/${embeddedId}?autoplay=${
              autoplay ? 1 : 0
            }&enablejsapi=1`}
            title={title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Video>
        <Typography type="subtitle" size="small">
          {title}
        </Typography>
      </Root>
    </>
  );
}

export default VideoBox;
