import { ReactElement } from 'react';
import {
  StrapiAgreements,
  StrapiAnnouncements,
  StrapiNews,
  StrapiNPS,
} from '@/common/types/strapi';

export const enum NoticeType {
  Announcement,
  News,
  NPS,
  Agreement,
}

export type NoticesModalProps = {
  title: string;
  isMobile: boolean;
  type: NoticeType;
  notices:
    | StrapiAnnouncements[]
    | StrapiNews[]
    | StrapiAgreements[]
    | StrapiNPS[];
  customContent?: ReactElement;
  openButton?: {
    text: string;
    icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  };
};
