import { Typography } from '@grupo-sbf/motriz-core';
import FormControl from '@mui/material/FormControl';
import styled, { css } from 'styled-components';

export const ContainerInput = styled.div`
  display: flex;
`;

export const ButtonText = styled(Typography)`
  display: flex;
  ${({ theme }) => css`
    color: ${theme.color.brand.primary};
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.fontSize.tiny};
    line-height: ${theme.lineHeight.normal};
    margin-left: ${theme.spacing[100]};
  `};
`;

export const Input = styled(FormControl)`
  width: 400px;
`;

export const InputSpan = styled.span`
  ${({ theme }) => css`
    color: ${theme.color.neutral[700]};
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.fontSize.xsmall};
    line-height: ${theme.lineHeight.normal};
  `};
`;
