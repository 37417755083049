import { centauro } from '@grupo-sbf/motriz-tokens/themes';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
  }

  body, html {
    -webkit-font-smoothing: antialiased;
  }

  body,input,button {
    font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
    font-size: 16px;
  }

  html {
    font-size: calc(14em * .0625);
  }

  [type="button"].MuiButtonBase-root.MuiButton-root.MuiButton-containedPrimary:not(.MuiButton-text){
    background-color: ${centauro.color.brand.primary};
  }

  [type="button"].MuiButtonBase-root.MuiButton-root.MuiButton-outlinedPrimary:not(.MuiButton-text) {
    color: ${centauro.color.brand.primary};
    border: 1px solid ${centauro.color.brand.primary};
  }

  button {
    cursor: pointer;
  }
`;

export default GlobalStyle;
