/* eslint-disable react/jsx-filename-extension */
/* eslint-disable @next/next/no-img-element */
// TODO: Need refactor
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable unicorn/consistent-destructuring */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-empty-function */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { LoaderContainer } from './LoaderFull.styled';

class LoaderFull extends PureComponent {
  componentDidMount = () => {
    this._addEscKeyListener();
  };

  componentWillUnmount = () => {
    this._removeEscKeyListener();
  };

  _keyHandler = (event) => {
    const { onClose } = this.props;
    const { nativeEvent } = event;

    // NOT ESC
    if (event.keyCode !== 27) {
      event.stopPropagation();
      if (nativeEvent) {
        nativeEvent.stopImmediatePropagation();
      }
      return false;
    }

    // ESC
    onClose();
    return true;
  };

  _addEscKeyListener = () => {
    if (window) {
      window.addEventListener('keyup', this._keyHandler, false);
      window.addEventListener('keydown', this._keyHandler, false);
    }
  };

  _removeEscKeyListener = () => {
    if (window) {
      window.removeEventListener('keyup', this._keyHandler, false);
      window.removeEventListener('keydown', this._keyHandler, false);
    }
  };

  render = () => {
    const { customDepth } = this.props;

    return (
      <LoaderContainer customDepth={customDepth}>
        <img src="/load.gif" width="30" alt="Carregando" />
      </LoaderContainer>
    );
  };
}

LoaderFull.propTypes = {
  onClose: PropTypes.func,
  customDepth: PropTypes.oneOf(['default', 'medium']),
};

LoaderFull.defaultProps = {
  onClose: () => {},
  customDepth: 'default',
};

export default LoaderFull;
