import axios from 'axios';
import { format } from 'date-fns';
import { signIn } from 'next-auth/react';
import { RequestHeaders } from './httpClientBff';

const getAuthHeader = () => {
  localStorage.removeItem('invalidSession');
  return JSON.parse(localStorage.getItem('currentAccount') || '{}')?.token;
};

export type HttpClientError = {
  response?: { status: number; data: Record<string, unknown> };
};

const formatErrorResponse = (err: HttpClientError & Error, url: string) => {
  const isCommonError = err instanceof Error;
  const out = {
    error: {
      axiosMessage: err.toString(),
      status: isCommonError ? err.response?.status : 0,
      data: {},
    },
    data: {},
  };

  if (process.env.NEXT_PUBLIC_DEBUG_HTTP) {
    console.error(
      `ERROR ${format(new Date(), 'dd/MM/yyyy hh:mm:ss')} ${url}:`,
      err.message,
    );
  }

  if (isCommonError && err.response) {
    const { status, data } = err.response;

    if (status) out.error.status = status;
    if (data) out.error.data = data;

    if (status === 401) {
      localStorage.setItem('invalidSession', 'true');
      console.error(`API responded with 401 for endpoint ${url}`);

      if (location && typeof location !== 'undefined') signIn('keycloak');
    }
  }

  return out;
};

const getJson = async (
  url: string,
  secondaryHeader: RequestHeaders,
  withCredentials = false,
) => {
  const headers = {
    ...secondaryHeader,
  };

  // Obter o authorization do usuário -> headers.Authorization = authHeader;
  if (withCredentials) headers.Authorization = getAuthHeader();

  try {
    const response = await axios.get(url, {
      headers,
      withCredentials,
    });

    const payload = { ...response, error: undefined };

    if (process.env.NEXT_PUBLIC_DEBUG_HTTP) {
      console.log(
        `INFO ${format(new Date(), 'dd/MM/yyyy hh:mm:ss')} ${url}:`,
        payload,
      );
    }

    return payload;
  } catch (error) {
    if (error instanceof Error) {
      return formatErrorResponse(error, url);
    }
  }
};

const postJson = async (
  url: string,
  body: Record<string, unknown>,
  secondaryHeader: RequestHeaders,
  withCredentials = true,
) => {
  try {
    const response = await axios.post(url, body, {
      headers: {
        Authorization: getAuthHeader(),
        ...secondaryHeader,
      },
      withCredentials,
    });

    const payload = { ...response, error: undefined };

    if (process.env.NEXT_PUBLIC_DEBUG_HTTP) {
      console.log(
        `INFO ${format(new Date(), 'dd/MM/yyyy hh:mm:ss')} ${url}:`,
        payload,
      );
    }

    return payload;
  } catch (error) {
    if (error instanceof Error) {
      return formatErrorResponse(error, url);
    }
  }
};

const putJson = async (
  url: string,
  body: Record<string, unknown>,
  secondaryHeader: RequestHeaders,
  withCredentials = false,
) => {
  try {
    const response = await axios.put(url, body, {
      headers: {
        Authorization: getAuthHeader(),
        ...secondaryHeader,
      },
      withCredentials,
    });
    const payload = { ...response, error: undefined };

    if (process.env.NEXT_PUBLIC_DEBUG_HTTP) {
      console.log(
        `INFO ${format(new Date(), 'dd/MM/yyyy hh:mm:ss')} ${url}:`,
        payload,
      );
    }

    return payload;
  } catch (error) {
    if (error instanceof Error) {
      return formatErrorResponse(error, url);
    }
  }
};

export { getJson, postJson, putJson, formatErrorResponse };
