import styled from 'styled-components';

export const StyledReactMarkDown = styled.div`
  margin-bottom: 40px;
  p {
    font-size: 16px;
    margin-bottom: 16px;
  }

  ul {
    padding-left: 24px;
  }

  li {
    margin-bottom: 8px;
  }

  img {
    width: 100%;
  }
`;
