import { signIn, useSession } from 'next-auth/react';
import LoaderFull from '@/common/components/LoaderFull/LoaderFull';
import { PrivateRouteProps } from '@/common/types/keycloak';
import useSessionAlive from '../hooks/useSessionAlive';
import { isRoleAllowed } from '../utils';
import NotAuthorized from './NotAuthorized';

export function PrivateRouteNoAuth({ children }: PrivateRouteProps) {
  return <>{children}</>;
}

export default function PrivateRoute({ children, roles }: PrivateRouteProps) {
  const { isSessionAlive } = useSessionAlive();
  const { status, data: session } = useSession({
    required: true,
    onUnauthenticated() {
      signIn('keycloak');
    },
  });

  if (status === 'loading' || !isSessionAlive)
    return <LoaderFull customDepth="large" />;
  if (status === 'authenticated' && !isRoleAllowed(roles, session))
    return <NotAuthorized />;

  return <>{children}</>;
}
