import { ThemeProvider as ThemeProviderMotriz } from '@grupo-sbf/motriz-core';
import centauroTokens from '@grupo-sbf/motriz-tokens/themes/centauro';
import { ThemeProvider, StylesProvider, CssBaseline } from '@material-ui/core';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { pt } from 'date-fns/locale';
import { Provider } from 'mobx-react';
import type { NextPageWithLayout } from 'next';
import type { AppProps as NextAppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { SessionProvider } from 'next-auth/react';
import { useState, useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import PrivateRoute from '@/common/routes/PrivateRoute';
import { ADMINISTRATOR_ROLE } from '@/common/types/keycloak';
import { sendPageView } from '@/common/utils/tracking/trackingEvents';
import { MotrizProvider } from '@/contexts/MotrizProvider';
import { UnleashProvider } from '@/contexts/UnleashProvider';
import { MANDATORY_ENVS } from '@/envs';
import Layout from '@/layout/Layout';
import { messages, userLang } from '@/locale/locale';
import AppStore from '@/stores/AppStore';
import GlobalStyle from '@/styles/GlobalStyle';
import { customTheme } from '@/styles/customTheme';
import defaultTheme from '@/styles/defaultTheme';

type MyAppPropsWithLayout = NextAppProps & {
  Component: NextPageWithLayout;
  cookies: unknown;
};

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const MyApp = ({
  Component,
  pageProps: { session, ...pageProps },
}: MyAppPropsWithLayout) => {
  const router = useRouter();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            refetchOnWindowFocus: false,
          },
        },
      }),
  );

  useEffect(() => {
    hotjar.initialize({ id: 2_252_844, sv: 6 });
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const pageType =
        router.pathname === '/' ? 'home' : router.pathname.replace('/', '');
      sendPageView(url, pageType);
    };

    const initialPageType =
      router.pathname === '/' ? 'home' : router.pathname.replace('/', '');

    sendPageView(router.pathname, initialPageType);

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  const mainLayoutProps = Component.mainLayoutProps;

  const componentLayout = (
    <Layout {...mainLayoutProps}>
      <PrivateRoute
        roles={['default-roles-portal-seller', 'default', ADMINISTRATOR_ROLE]}
      >
        <Component {...pageProps} />
      </PrivateRoute>
    </Layout>
  );

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, maximum-scale=1, minimum-scale=1, initial-scale=1, user-scalable=no, shrink-to-fit=no"
        />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <title>Portal do Seller | Centauro Marketplace</title>
      </Head>
      <StylesProvider injectFirst>
        <IntlProvider locale={userLang} messages={messages['pt-BR']}>
          <SessionProvider session={session}>
            <MotrizProvider>
              <ThemeProvider theme={defaultTheme}>
                <CssBaseline />
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={pt}
                >
                  <Provider AppStore={AppStore}>
                    <UnleashProvider>
                      <QueryClientProvider client={queryClient}>
                        <GlobalStyle />
                        <ThemeProviderMotriz
                          theme={centauroTokens}
                          customTheme={customTheme}
                        >
                          {componentLayout}
                        </ThemeProviderMotriz>
                      </QueryClientProvider>
                    </UnleashProvider>
                  </Provider>
                </LocalizationProvider>
              </ThemeProvider>
            </MotrizProvider>
          </SessionProvider>
        </IntlProvider>
      </StylesProvider>
    </>
  );
};

MANDATORY_ENVS.forEach((env) => {
  if (typeof env.procEnv === 'undefined') {
    throw new TypeError(
      `Erro de configuração de projeto. A env ${env.id} provavelmente não existe no arquivo .env local ou no vault/github secrets`,
    );
  }
});

export default MyApp;
