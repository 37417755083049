import { observable, action, makeObservable } from 'mobx';

class LoginStore {
  email = '';

  setEmail = (email) => (this.email = email);

  password = '';

  setPassword = (password) => (this.password = password);

  constructor() {
    makeObservable(this, {
      email: observable,
      setEmail: action,
      password: observable,
      setPassword: action,
    });
  }
}

export default LoginStore;
