/* eslint-disable unicorn/prefer-set-has */
import {
  StrapiAgreements,
  StrapiAnnouncements,
  StrapiNews,
  StrapiNPS,
} from '@/common/types/strapi';
import { NoticeType } from './NoticesModal.types';

export const embedIDFrom = (youtubeVideo: string): string => {
  const urlRegex =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;

  if (!urlRegex.test(youtubeVideo)) {
    throw new TypeError('embedURLFrom: URL doesnt seem to be valid.');
  }

  return youtubeVideo.replace(urlRegex, '$7');
};

export const hasNewNoticesContent = (
  type: NoticeType,
  notices: (StrapiAnnouncements | StrapiAgreements | StrapiNews | StrapiNPS)[],
): boolean => {
  const storageAnnouncements: string =
    (typeof localStorage !== 'undefined' &&
      localStorage.getItem('announcementAlreadyOpened')) ||
    '[]';

  const storageNewsIds: string[] = JSON.parse(
    (typeof localStorage !== 'undefined' &&
      localStorage.getItem('newsAlreadyOpened')) ||
      '[]',
  ).map((item: { id: string }) => item.id);

  const storageAgreementsIds: string[] = JSON.parse(
    (typeof localStorage !== 'undefined' &&
      localStorage.getItem('agreementsAlreadyOpened')) ||
      '[]',
  ).map((item: { id: string }) => item.id);

  const npsIds: string[] = JSON.parse(
    (typeof localStorage !== 'undefined' &&
      localStorage.getItem('npsAlreadyOpened')) ||
      '[]',
  ).map((item: { id: string }) => item.id);

  if (type === NoticeType.News) {
    return !notices
      .map((item) => item.id)
      .every((id) => storageNewsIds.includes(id));
  }

  if (type === NoticeType.NPS) {
    return !notices.map((item) => item.id).every((id) => npsIds.includes(id));
  }

  if (type === NoticeType.Agreement) {
    return storageAgreementsIds.includes(
      (notices as StrapiAgreements[])[0].attributes.agreementId || '',
    );
  }

  return storageAnnouncements !== JSON.stringify(notices);
};
