import { Fragment, ReactNode } from 'react';
import { BREAKPOINTS_ENUM } from '@/common/hooks/useBreakpoint/types/index';
type MobileViewProps = {
  children: ReactNode;
};
import { useBreakpoint } from '../../hooks/useBreakpoint/useBreakpoint';

export const MobileView = ({ children }: MobileViewProps) => {
  const size = useBreakpoint();

  const isMobile =
    size === BREAKPOINTS_ENUM.XS ||
    size === BREAKPOINTS_ENUM.SM ||
    size === BREAKPOINTS_ENUM.MD;

  return isMobile ? <Fragment>{children}</Fragment> : undefined;
};
