import ptBr from './data/pt-BR.json';
import enUs from './data/en-US.json';

const messages = { 'pt-BR': { ...ptBr }, 'en-US': { ...enUs } };

const userLang = (() => {
  if (
    typeof window !== 'undefined' &&
    typeof window.navigator !== 'undefined'
  ) {
    return navigator.language || navigator.userLanguage;
  }

  return 'pt-BR';
})();

export { messages, userLang };
