const SENTRY_RELEASE = process.env.NEXT_PUBLIC_APP_VERSION;
const release = SENTRY_RELEASE.replaceAll('-', '.');

const SentryConfig = {
  release: `v${release}`,
  allowUrls: [
    /https?:\/\/((www|static)\.)?marketplace.centauro\.com\.br/,
    /https?:\/\/((www|static)\.)?new-marketplace.centauro\.com\.br/,
    /https?:\/\/((www|static)\.)?centauro\.com\.br/,
  ],
  tracesSampler: (samplingContext) => {
    if (
      samplingContext.transactionContext.name ===
        'GET /api/auth/[...nextauth]' ||
      samplingContext.transactionContext.operation === 'http.server' ||
      samplingContext.transactionContext.name === 'GET /api/auth/session' ||
      samplingContext.transactionContext.name === 'GET /api/healthcheck'
    ) {
      return 0;
    }

    return 0.2;
  },
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',
    // Avast extension error
    '_avast_submit',
  ],
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Third
    /s\.go-mpulse\.net/i,
    /googletagmanager\.com/i,
    /graph\.facebook\.com/i,
    /connect\.facebook\.net\/en_US\/all\.js/i,
  ],
};

export default SentryConfig;
