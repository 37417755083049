import Link from 'next/link';
import StaticImage from '@/common/components/Img';
type LogoPropsType = {
  alt: string;
  height: number;
  href: string;
  src: string;
  title: string;
  width: number;
};

export const Logo = ({ alt, height, href, src, width }: LogoPropsType) => {
  return (
    <Link href={href} passHref>
      <StaticImage src={src} alt={alt} width={width} height={height} />
    </Link>
  );
};

export default Logo;
