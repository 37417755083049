import { useFlag } from '@unleash/proxy-client-react';

const useUnleash = (toggleName: string) => {
  if (toggleName === '') {
    throw new Error('Feature Toggle name passed to useToggle is invalid');
  }

  return useFlag(toggleName);
};

export default useUnleash;
