// This entire file doesnt work with Aphrodite...
import { centauro } from '@grupo-sbf/motriz-tokens/themes';
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      '"Roboto"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: centauro.color.brand.primary,
    },
    secondary: {
      main: centauro.color.neutral[300],
    },
    text: {
      primary: centauro.color.neutral[700],
      secondary: centauro.color.decorative.volleyball,
    },
    background: {
      default: centauro.color.neutral[200],
    },
    action: {
      hover: centauro.color.neutral[200],
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: centauro.color.neutral[100],
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none',
      },
    },
    MuiIconButton: {
      root: {
        padding: 8,
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        fontWeight: 'inherit',
        minHeight: 42,
      },
      textSizeLarge: {
        fontSize: 'inherit',
        padding: '9px 12px',
      },
    },
    MuiBadge: {
      badge: {
        width: 15,
        minWidth: 15,
        border: `1px solid ${centauro.color.neutral[100]}`,
        height: 15,
        padding: 0,
        fontSize: 8,
        backgroundColor: centauro.color.alert[100],
      },
      anchorOriginTopRightRectangle: {
        top: 4,
        left: 8,
      },
    },
    MuiMenuItem: {
      root: {
        borderRadius: '8px',
        margin: '8px',
        maxWidth: '200px',
        paddingLeft: '8px',
        color: centauro.color.neutral[500],
        '&.Mui-selected': {
          backgroundColor: centauro.color.brand.primary,
          color: centauro.color.neutral[100],

          '&:hover': {
            backgroundColor: centauro.color.brand.primary,
          },
        },
      },
    },
    MuiListItemText: {
      root: {
        paddingLeft: '8px',
      },
      secondary: {
        fontWeight: 500,
        fontSize: 'inherit',
      },
    },
    MuiDialogActions: {
      root: {
        padding: 24,
      },
    },
    MuiBreadcrumbs: {
      ol: {
        lineHeight: '1rem', // 16px
      },
    },
  },
});

export default theme;
