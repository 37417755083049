import { captureException as sentryCaptureException } from '@sentry/nextjs';
import { useMutation } from 'react-query';
import { DanfeType } from '@/common/types/order';
import { ReportCatalogPayload } from '@/modules/catalog/components/ReportModal/ReportModalCatalog.types';
import {
  CancelOrderPayload,
  TrackingPayload,
} from '@/modules/order/components/OrderDetail/OrderDetail.types';
import { getJson, postJson, putJson } from './httpClient/httpClientBff';

const webApiUrl = process.env.NEXT_PUBLIC_PORTAL_SELLER_WEBAPI;
const ordersUrl = `${webApiUrl}v1/orders`;

const getOrders = (
  sincedate: string,
  offset = 0,
  quantity = 20,
  status = '',
  sort = 'lastupdate',
  header = {},
) => {
  const url = `${ordersUrl}?offset=${offset}&limit=${quantity}&sort=${sort}&status=${status}&last_update=${sincedate}`;
  return getJson(url, undefined, header);
};

const createReportGateway = (
  body: ReportCatalogPayload,
  idToken: string,
  header = {},
) => postJson(`${webApiUrl}v1/reports/createReport`, body, idToken, header);

const getOrderById = (id: string) => getJson(`${ordersUrl}/${id}`);

type TrackingDeliveredType = {
  idOrder: string | number;
  body: { date: string };
};
const putTrackingDelivered = async (params: TrackingDeliveredType) => {
  const req = await putJson(
    `${ordersUrl}/${params.idOrder}/delivered`,
    params.body,
  );

  if (req.error)
    throw new Error(
      `Failed to update order ${params.idOrder} status: ${JSON.stringify(
        req.error,
      )} `,
    );

  return req;
};

const useDeliveredOrder = () => {
  return useMutation(putTrackingDelivered);
};

type TrackingInvoicedType = {
  idOrder: string | number;
  body: DanfeType;
};

const putTrackingInvoiced = async (params: TrackingInvoicedType) => {
  const req = await putJson(
    `${ordersUrl}/${params.idOrder}/invoiced`,
    params.body,
  );

  if (req.error)
    throw new Error(
      `Failed to update order ${params.idOrder} status: ${JSON.stringify(
        req.error,
      )} `,
    );

  return req;
};

const useInvoicedOrder = () => {
  return useMutation(putTrackingInvoiced);
};

type TrackingCanceledType = {
  idOrder: number;
  protocolId: string;
  body: CancelOrderPayload;
};
const putTrackingCanceled = async (params: TrackingCanceledType) => {
  const protocolParam = params.protocolId
    ? `?protocolId=${params.protocolId}`
    : '';

  const req = await putJson(
    `${ordersUrl}/${params.idOrder}/canceled${protocolParam}`,
    params.body,
  );

  if (req.error)
    throw new Error(
      `Failed to update cancel ${params.idOrder} status: ${JSON.stringify(
        req.error,
      )} `,
    );

  return req;
};

const useCanceledOrder = () => {
  return useMutation(putTrackingCanceled);
};

type SentTrackingType = {
  idOrder: string;
  body: TrackingPayload;
};
const putTrackingShipped = async (params: SentTrackingType) => {
  const req = await putJson(`${ordersUrl}/${params.idOrder}/sent`, params.body);

  if (req.error) {
    sentryCaptureException(JSON.stringify(req.error));
    throw new Error(`Failed to update order: ${JSON.stringify(req.error)}`);
  }

  return req;
};

const useSentOrder = () => {
  return useMutation(putTrackingShipped);
};

export {
  getOrders,
  getOrderById,
  useSentOrder,
  useCanceledOrder,
  useInvoicedOrder,
  useDeliveredOrder,
  createReportGateway,
  putTrackingDelivered,
  putTrackingInvoiced,
  putTrackingCanceled,
  putTrackingShipped,
};
