import { Bell, Star } from '@grupo-sbf/motriz-icons/centauro';
import { media } from '@grupo-sbf/motriz-utils';
import styled, { css } from 'styled-components';

export const HeaderContainer = styled.header`
  ${({ theme }) => css`
    background-color: ${theme.color.decorative.basketball};
    width: 100%;
    height: ${theme.spacing[900]};
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 950;

    ${media.lessThan('small')} {
      height: 52px;
    }
  `}
`;

export const LogoContainer = styled.div`
  ${({ theme }) => css`
    margin-left: ${theme.spacing[300]};
  `}
`;

export const ButtonSVG = styled.button`
  background-color: transparent;
  border: none;
  ${({ theme }) => css`
    padding: ${theme.spacing[200]};
  `}
`;

export const TypographyContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-right: ${theme.spacing[300]};
    justify-content: flex-end;
    flex-grow: 1;

    @media (max-width: 793px) {
      justify-content: flex-end;
      .typography {
        display: none;
      }
    }
  `}
`;

export const NotificationContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing[100]}
    margin-left: auto;
  `}
`;

export const BellIcon = styled(Bell)`
  ${({ theme }) => css`
    color: ${theme.color.neutral[100]};
  `}
`;

export const StarIcon = styled(Star)`
  ${({ theme }) => css`
    color: ${theme.color.neutral[100]};
  `}
`;
