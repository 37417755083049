import TestStore from './TestStore';
import LoginStore from './LoginStore';
import DataTableStore from './DataTableStore';
import OrderDetailStore from './OrderDetailStore';
import QuestionsAndAnswersStore from './QuestionsAndAnswersStore';

class AppStore {
  constructor() {
    this.TestStore = new TestStore();
    this.LoginStore = new LoginStore();
    this.DataTableStore = new DataTableStore();
    this.OrderDetailStore = new OrderDetailStore();
    this.OrderListStore = new DataTableStore();
    this.CatalogStore = new DataTableStore();
    this.QuestionsAndAnswersStore = new QuestionsAndAnswersStore();
  }
}

const store = new AppStore();

export default store;
