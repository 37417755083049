import { Skeleton } from '@grupo-sbf/motriz-core';
import { media } from '@grupo-sbf/motriz-utils';
import Link from 'next/link';
import styled, { css } from 'styled-components';

export const Sidebar = styled.div<{ isMenuOpen: boolean; expanded: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  width: auto;

  ${({ theme, isMenuOpen, expanded }) => css`
    width: ${expanded ? '240px' : '88px'};
    border-radius: ${theme.borderRadius.medium};
    padding-left: ${theme.spacing[200]};
    background-color: ${theme.color.neutral[100]};
    gap: ${theme.spacing[100]};
    padding: ${theme.spacing[100]};

    ${media.lessThan('large')} {
      display: ${isMenuOpen ? 'block' : 'none'};
      transition: transform 0.3s ease;
      transform: ${isMenuOpen ? 'translateX(0)' : 'translateX(-100%)'};

      h2 {
        font-size: ${theme.fontSize.xsmall};
      }
    }

    #seller {
      border-radius: ${theme.borderRadius.medium};

      h2 {
        font-weight: ${theme.fontWeight.bold};
      }
    }

    #seller[aria-expanded='false'] {
      margin-bottom: ${theme.spacing[300]};
    }

    #seller-painel {
      margin: ${theme.spacing[0]};
      margin-bottom: ${theme.spacing[300]};
    }

    #menu,
    #menu-selected {
      border-radius: ${theme.borderRadius.medium};
      padding: ${theme.spacing[150]} ${theme.spacing[0]};
      margin-top: ${theme.spacing[50]};
    }

    #menu-selected {
      background-color: ${theme.color.decorative.basketball};
      transition: background-color 0.3s ease;

      h2,
      svg {
        color: ${theme.color.neutral[100]};
      }

      :hover {
        background-color: ${theme.color.neutral[200]};

        svg,
        span,
        h2 {
          color: ${theme.color.neutral[600]};
        }
      }
    }

    h2 {
      font-weight: ${theme.fontWeight.regular};
      color: ${theme.color.neutral[600]};
      margin-left: ${theme.spacing[100]};
      transition: color 0.3s ease;
    }

    svg {
      color: ${theme.color.neutral[600]};
      margin-left: ${theme.spacing[50]};
    }

    button[aria-expanded='true'] {
      background-color: ${theme.color.neutral[200]};
      transition: background-color 0.3s ease;

      svg,
      h2 {
        color: ${theme.color.neutral[600]};
      }
    }

    button[aria-expanded='true'][id='seller'] {
      background-color: ${theme.color.neutral[0]};
      transition: background-color 0.3s ease;

      :hover {
        background-color: ${theme.color.neutral[200]};

        h2 {
          color: ${theme.color.neutral[600]};
        }
      }

      h2,
      svg {
        color: ${theme.color.neutral[700]};
      }
    }
  `}
  #icon-chevron {
    ${({ theme }) => css`
      margin-left: ${theme.spacing[0]} !important;
    `}
  }
`;

export const MenuItem = styled(Link)<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  ${({ theme, isSelected }) => css`
    font-size: ${theme.fontSize.xsmall};
    gap: ${theme.spacing[100]};
    border-radius: ${theme.borderRadius.medium};
    padding: ${theme.spacing[100]} ${theme.spacing[100]} ${theme.spacing[100]}
      ${theme.spacing[400]};
    margin-bottom: ${theme.spacing[100]};
    color: ${isSelected ? theme.color.neutral[700] : theme.color.neutral[600]};
    font-weight: ${isSelected ? theme.fontWeight.bold : 'normal'};

    :hover {
      background-color: ${theme.color.neutral[200]};
    }
  `}
`;

export const Trace = styled.span<{ isSelected: boolean }>`
  ${({ theme, isSelected }) => css`
    margin-left: ${isSelected ? theme.spacing[50] : 'initial'};
    font-size: ${isSelected ? theme.fontSize.medium : 'initial'};
    font-weight: ${isSelected ? 700 : 'normal'};
    color: ${isSelected ? theme.color.neutral[100] : theme.color.neutral[0]};
  `}
`;

export const FooterMenu = styled.div`
  position: absolute;
  height: 60px;
  width: 100%;
  bottom: 6px;

  ${({ theme }) => css`
    background-color: ${theme.color.neutral[100]};
    left: ${theme.spacing[0]};
    padding: ${theme.spacing[0]} ${theme.spacing[150]};
  `}
`;

export const ExpandingButton = styled.button`
  position: absolute;
  border: none;

  ${({ theme }) => css`
    background: ${theme.color.neutral[0]};
    right: ${theme.spacing[0]};
    bottom: ${theme.spacing[0]};
    padding: 10px 14px;
  `}
`;

export const ContentAccordion = styled.div`
  flex: 1;
  overflow-y: auto;
  height: 85vh;
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 900;

  ${({ theme }) => css`
    top: ${theme.spacing[0]};
    left: ${theme.spacing[0]};
  `}
`;

export const SkeletonMenu = styled(Skeleton)`
  position: fixed;
`;
