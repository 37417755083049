import { Autocomplete, TextField } from '@mui/material';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useDebounce } from '@/common/hooks/useDebounce';
import { KeycloakGroupType } from '@/common/types/keycloak';
import { getKeycloakUserGroups } from '@/services/keycloakModule';
import { ProgressContainerStyled, ProgressStyled } from './SellerSelect.styled';

const SellerSelect = ({
  value,
  setValue,
  token,
  selectedValue = '',
  size,
}: {
  value: KeycloakGroupType | undefined;
  setValue?: (newValue: KeycloakGroupType) => void;
  token: string;
  selectedValue?: string;
  useId?: boolean;
  size?: 'small' | 'medium';
}) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [inputValue, setInputValue] = useState<string>('');
  const debouncedValue = useDebounce(inputValue, 500);
  const [filteredUserGroupList, setFilteredUserGroupList] =
    useState<KeycloakGroupType[]>();
  const [loadingSearch, setLoadingSearch] = useState(false);

  const onInputChangeHandler = (newInputValue: KeycloakGroupType | string) => {
    const name =
      typeof newInputValue !== 'string'
        ? newInputValue.name
        : newInputValue || '';

    setInputValue(name);
  };

  const updateInputWithSelectedValue = useCallback(
    (groups: KeycloakGroupType[]) => {
      if (selectedValue !== '' && Array.isArray(groups) && groups.length > 0) {
        const selectedGroup = groups.find(
          (group) => group.codigo === selectedValue,
        );

        if (selectedGroup && Object.keys(selectedGroup).length > 0) {
          setInputValue(selectedGroup?.name || '');

          if (setValue) {
            setValue(selectedGroup);
          }
        }
      }
    },
    [selectedValue, setValue],
  );

  const getGroups = useCallback(
    async (debouncedName: string) => {
      setLoadingSearch(true);
      if (token && debouncedName !== value?.name) {
        const groupsRequest = await getKeycloakUserGroups(debouncedName);

        if (Array.isArray(groupsRequest)) {
          setFilteredUserGroupList(groupsRequest);
        }

        if (selectedValue !== '' && isFirstLoad) {
          updateInputWithSelectedValue(groupsRequest);
          setIsFirstLoad(false);
        }

        return groupsRequest;
      }

      setLoadingSearch(false);
    },
    [
      isFirstLoad,
      selectedValue,
      token,
      updateInputWithSelectedValue,
      value?.name,
    ],
  );

  useEffect(() => {
    if (selectedValue !== '' && (isFirstLoad || debouncedValue.length >= 0)) {
      getGroups(debouncedValue);
    }

    if (selectedValue === '' && value?.codigo !== selectedValue) {
      getGroups(debouncedValue);
    }
  }, [
    debouncedValue,
    getGroups,
    isFirstLoad,
    selectedValue,
    token,
    value,
    value?.codigo,
  ]);

  return (
    <Autocomplete
      style={{ minWidth: '200px' }}
      value={value}
      onChange={(
        event: SyntheticEvent<Element, Event>,
        newValue: NonNullable<string | KeycloakGroupType>,
      ) => {
        if (setValue && typeof newValue === 'object') {
          setValue(newValue);
        }
      }}
      inputValue={inputValue}
      id="free-solo-2-demo"
      disableClearable
      getOptionLabel={(item: KeycloakGroupType | string) => {
        return typeof item === 'object' ? item.name : item || '';
      }}
      onInputChange={(e, val) => onInputChangeHandler(val)}
      options={filteredUserGroupList || []}
      renderInput={(params) => {
        return (
          <ProgressContainerStyled>
            <TextField
              {...params}
              placeholder="Selecionar seller"
              variant="outlined"
              size={size ? size : 'small'}
              InputProps={{ ...params.InputProps, type: 'text' }}
            />

            {loadingSearch && <ProgressStyled />}
          </ProgressContainerStyled>
        );
      }}
    />
  );
};

export default SellerSelect;
