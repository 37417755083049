import { isProdEnv } from '../utils/environment';

export const UNLEASH_CONFIG = {
  url: process.env.NEXT_PUBLIC_UNLEASH_PROXY_URL || '',
  clientKey: process.env.NEXT_PUBLIC_UNLEASH_PROXY_KEY || '',
  refreshInterval: 900,
  appName: 'portal-seller-front',
  environment: !isProdEnv ? 'development' : 'production', // until that moment, dev enviroment wasnt
  disableMetrics: true,
};
