import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDebounceCallback } from '../useDebounce/index';
import {
  BREAKPOINTS_ENUM,
  breakpoints,
  BreakpointProviderProps,
} from './types/index';

const defaultValue = {};
const BreakpointContext = createContext(defaultValue);

const getBreakpoint = (window: Window): BREAKPOINTS_ENUM => {
  if (window.matchMedia(`(min-width: ${breakpoints.xl})`).matches) {
    return BREAKPOINTS_ENUM.XL;
  }
  if (window.matchMedia(`(min-width: ${breakpoints.lg})`).matches) {
    return BREAKPOINTS_ENUM.LG;
  }
  if (window.matchMedia(`(min-width: ${breakpoints.md})`).matches) {
    return BREAKPOINTS_ENUM.MD;
  }
  if (window.matchMedia(`(min-width: ${breakpoints.sm})`).matches) {
    return BREAKPOINTS_ENUM.SM;
  }
  if (window.matchMedia(`(min-width: 0em)`).matches) {
    return BREAKPOINTS_ENUM.XS;
  }

  return BREAKPOINTS_ENUM.XL;
};

const BreakpointProvider = ({
  children,
  initialBreakpoint = BREAKPOINTS_ENUM.SM,
}: BreakpointProviderProps) => {
  const [breakpoint, setBreakpoint] =
    useState<BREAKPOINTS_ENUM>(initialBreakpoint);

  const debouncedHandleResize = useDebounceCallback(() => {
    const newViewport = getBreakpoint(window);
    setBreakpoint(newViewport);
  }, 100);

  useEffect(() => {
    const currentBreakpoint = getBreakpoint(window);
    setBreakpoint(currentBreakpoint);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      debouncedHandleResize.cancel();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [breakpoint]);

  const value = useMemo(() => breakpoint, [breakpoint]);

  return (
    <BreakpointContext.Provider value={value}>
      {children}
    </BreakpointContext.Provider>
  );
};

function useBreakpointDevice() {
  const context = useContext(BreakpointContext);

  return useMemo(() => {
    const isDesktop =
      context === BREAKPOINTS_ENUM.LG ||
      context === BREAKPOINTS_ENUM.XL ||
      context === BREAKPOINTS_ENUM['2XL'];

    const isMobile =
      context === BREAKPOINTS_ENUM.XS ||
      context === BREAKPOINTS_ENUM.SM ||
      context === BREAKPOINTS_ENUM.MD;

    return {
      isDesktop,
      isMobile,
    };
  }, [context]);
}

function useBreakpoint() {
  const context = useContext(BreakpointContext);
  if (context === defaultValue) {
    throw new Error('useBreakpoint is not used within a BreakpointProvider');
  }
  return context;
}

export {
  /** can be used on any component level to get the latest Viewport */
  useBreakpoint,
  /** should only be set at application level */
  BreakpointProvider,
  /** never consume this directly, only exported for our mocked provider, see our mocks! */
  BreakpointContext,
  useBreakpointDevice,
};
