import { DebounceSettingsLeading } from 'lodash';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useRef, useState } from 'react';

/**
 *  This hook implements the debounce effect and returns a function.
 *  The callback will execute after a delay time.
 *
 * @example
 * import { useDebounceCallback } from '@/common/hooks/useDebounce/useDebounce';
 *
 * const SomeComponent = () => {
 *
 *   const debounceFn = useDebounceCallback(() => {}, 100);
 *
 *   return (<div onClick={debounceFn}>Dialog Content</div>);
 * }
 *
 * @param callback the method that will be called after the delay time;
 * @param delay the waiting time in milliseconds;
 * @param options lodash debounce options;
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDebounceCallback<T extends (...args: any[]) => any>(
  callback: T,
  delay = 0,
  options?: DebounceSettingsLeading,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): T & any {
  return useCallback(debounce(callback, delay, options), [
    callback,
    delay,
    options,
  ]);
}

/**
 *  This hook implements the debounce effect and returns a value.
 *
 * @example
 * import { useDebounce } from '@/common/hooks/useDebounce/useDebounce';
 *
 * const debouncedValue = useDebounce<string>(value, 500)
 *
 * useEffect(() => {
 *   // Do fetch here...
 *   // Triggers when "debouncedValue" changes
 * }, [debouncedValue])
 *
 * @param value the value that will be called after the delay time;
 * @param delay the waiting time in milliseconds;
 */

export function useDebounce<T>(
  value: T,
  delay = 0,
  options?: DebounceSettingsLeading,
): T {
  const previousValue = useRef(value);
  const [current, setCurrent] = useState(value);
  const debouncedCallback = useDebounceCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (value: T) => setCurrent(value),
    delay,
    options,
  );
  useEffect(() => {
    if (value !== previousValue.current) {
      debouncedCallback(value);
      previousValue.current = value;
      return debouncedCallback.cancel;
    }
  }, [value]);

  return current;
}
