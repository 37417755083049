import styled, { css, keyframes } from 'styled-components';

const opacityKeyFrames = keyframes`
 0% { opacity: 0 }
 100% { opacity: 1 }
`;

export const LoaderContainer = styled.div<{
  customDepth: 'default' | 'large';
}>`
  ${({ theme, customDepth }) => css`
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${theme.color.neutral[100]};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: ${customDepth === 'default' ? 10 : 1200};
    top: ${theme.spacing[0]};
    left: ${theme.spacing[0]};
    animation-name: ${opacityKeyFrames};
    animation-duration: 300ms;

    img {
      margin: 0;
    }
  `}
`;
