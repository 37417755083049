import { Button, Typography } from '@mui/material';
import styled from 'styled-components';

export const StyledRoot = styled.div`
  text-align: center;
`;

export const StyledHeading = styled(Typography).attrs({
  variant: 'h5',
})`
  color: rgb(26, 26, 26);
  text-align: left;
`;

export const ImageBanner = styled.img.attrs({
  src: '/icons/pesquisaNps.png',
  alt: 'Pesquisa de NPS',
})`
  width: 108px;
  margin-bottom: -20px;
`;

export const StyledCTAButton = styled(Button).attrs({
  color: 'primary',
  variant: 'contained',
  sx: { textTransform: 'none' },
})`
  width: 228px;
  height: 40px;
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const StyledLeaveButton = styled(Button)`
  color: #4d4d4d;
  box-shadow: none;
  text-transform: none;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 4px 16px;
  margin-left: 8px;
  line-height: 1rem;
  min-height: 1.25rem;
  width: 172px;
  background-color: #ffffff;
`;
