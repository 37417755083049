import { observable, action, makeObservable } from 'mobx';
import moment from 'moment';
import { getOrderById } from '../services/orderModule';

class OrderDetailStore {
  customer = {};

  order = {};

  invoice = {};

  delivery = {};

  shipping = {};

  orderItems = [];

  actualStatus = {};

  statusList = [];

  freight = {};

  orderValues = {};

  isLoading = false;

  isEmpty = false;

  setEmpty = (value) => (this.isEmpty = value);

  setOrder = (value) => (this.order = value);

  getAllOrderStatus = (order, invoice, shipping, delivery) => {
    const orderStatusData = [
      'NEW',
      'APPROVED',
      'INVOICED',
      'SENT',
      'DELIVERED',
    ];
    const listOfStatus = [];
    const actualStatus = {};
    if (order) {
      if (order.status === 'NOT_APPROVED') {
        actualStatus.status = 'order.status.not_approved';
        actualStatus.message =
          'O Pagamento foi reprovado pela operadora financeira, reveja os dados e entre contato com a mesma.';
        actualStatus.srcImg =
          'https://imgcentauro-a.akamaihd.net/icons/20200624/Reprovado.svg';
        listOfStatus.push({
          id: order.id,
          status: actualStatus.status,
          message: actualStatus.message,
          srcImg: actualStatus.srcImg,
          date: moment(order.orderDate).format('DD/MM/YYYY'),
          messageLabel: 'Pagamento Reprovado',
        });
      } else if (order.status === 'CANCELED') {
        actualStatus.status = 'order.status.canceled';
        actualStatus.message =
          'O pedido foi cancelado, entre em contato com a central de informações para mais detalhes';
        actualStatus.srcImg =
          'https://imgcentauro-a.akamaihd.net/icons/20200624/cancelado.svg';
        listOfStatus.push({
          id: order.id,
          status: actualStatus.status,
          message: actualStatus.message,
          srcImg: actualStatus.srcImg,
          date: moment(order.orderDate).format('DD/MM/YYYY'),
          messageLabel: 'Este pedido foi cancelado!',
        });
      } else {
        for (let i = 0; i < orderStatusData.length; i += 1) {
          switch (orderStatusData[i]) {
            case 'NEW':
              actualStatus.status = 'order.status.new';
              actualStatus.message =
                'Pagamento ainda não consta. Consulte o banco pois dependendo da administradora do cartão o valor pode levar até 72 horas para ficar disponível na conta.';
              actualStatus.srcImg =
                'https://imgcentauro-a.akamaihd.net/icons/20200624/Aguardando.svg';

              listOfStatus.push({
                id: order.id,
                status: actualStatus.status,
                message: actualStatus.message,
                srcImg: actualStatus.srcImg,
                date: moment(order.orderDate).format('DD/MM/YYYY'),
                messageLabel: 'Processado',
              });

              break;
            case 'APPROVED':
              if (order.approvalDate !== null) {
                actualStatus.status = 'order.status.approved';
                actualStatus.message =
                  'Pagamento Aprovador pela instituição financeira.';
                actualStatus.srcImg =
                  'https://imgcentauro-a.akamaihd.net/icons/20200623/MoneyBadge/badge%20%282%29.png';
                listOfStatus.push({
                  id: order.id,
                  status: actualStatus.status,
                  message: actualStatus.message,
                  srcImg: actualStatus.srcImg,
                  date: moment(order.approvalDate).format('DD/MM/YYYY'),
                  messageLabel: 'Pago',
                });
                break;
              }
              break;
            case 'INVOICED':
              if (invoice.date !== null) {
                actualStatus.status = 'order.status.invoiced';
                actualStatus.message =
                  'Sua nota fiscal foi gerada e já está disponível na plataforma';
                actualStatus.srcImg =
                  'https://imgcentauro-a.akamaihd.net/icons/20200623/CheckBadge/badge.png';
                listOfStatus.push({
                  id: order.id,
                  message: actualStatus.message,
                  status: actualStatus.status,
                  srcImg: actualStatus.srcImg,
                  date: moment(invoice.date).format('DD/MM/YYYY'),
                  messageLabel: 'Nota fiscal já está disponível',
                });
                break;
              }
              break;
            case 'SENT':
              if (shipping.shippingDate !== null) {
                actualStatus.status = 'order.status.sent';
                actualStatus.message = 'Enviado para transportadora';
                actualStatus.srcImg =
                  'https://imgcentauro-a.akamaihd.net/icons/20200624/Enviado.svg';
                listOfStatus.push({
                  id: order.id,
                  message: actualStatus.message,
                  status: actualStatus.status,
                  srcImg: actualStatus.srcImg,
                  date: moment(shipping.shippingDate).format('DD/MM/YYYY'),
                  messageLabel: 'Enviado para transportadora',
                });
                break;
              }
              break;
            case 'DELIVERED':
              if (delivery.deliveryDate !== null) {
                actualStatus.status = 'order.status.delivered';
                actualStatus.message = 'Entregue ao destinatário';
                actualStatus.srcImg =
                  'https://imgcentauro-a.akamaihd.net/icons/20200624/Entregue.svg';
                listOfStatus.push({
                  id: order.id,
                  srcImg: actualStatus.srcImg,
                  message: actualStatus.message,
                  date: moment(delivery.deliveryDate).format('DD/MM/YYYY'),
                  status: actualStatus.status,
                  messageLabel: 'Entregue com sucesso',
                });
                break;
              }
              break;
            default:
              listOfStatus.push('end');
          }
        }
      }
    }
    this.actualStatus = actualStatus;
    listOfStatus.push('end');
    this.statusList = listOfStatus;
  };

  setCustomer = (value) => {
    const clientDataLabels = [
      'Nome',
      'CPF / CNPJ',
      'Email',
      'Rua / Avenida',
      'Número',
      'Bairro',
      'CEP',
      'Cidade',
    ];

    function formatValueDocumentToCpfOrCnpj() {
      if (value.document.length > 11) {
        return value.document.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          '$1.$2.$3/$4-$5',
        );
      }
      return value.document.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        '$1.$2.$3-$4',
      );
    }

    const clientDataValues = [
      value.name,
      formatValueDocumentToCpfOrCnpj(),
      value.email,
      value.addressData.address,
      value.addressData.number,
      value.addressData.neighborhood,
      value.addressData.zipcode,
      value.addressData.city,
    ];
    const mapClientsFields = {};
    for (let i = 0; i < clientDataLabels.length; i += 1) {
      mapClientsFields[clientDataLabels[i]] = clientDataValues[i];
    }

    return (this.customer = mapClientsFields);
  };

  setInvoice = (value) => (this.invoice = value);

  setDelivery = (value) => (this.delivery = value);

  setShipping = (value) => (this.shipping = value);

  setOrderItems = (value) => (this.orderItems = value);

  setFreight = (value) => (this.freight = value);

  setOrderValues = (value) => (this.orderValues = value);

  setLoading = (value) => (this.isLoading = value);

  getUserData = async (id, idToken) => {
    this.setLoading(true);
    this.setEmpty(false);
    const token = idToken;
    const response = await getOrderById(id, token);
    if (response.error) {
      this.setLoading(false);
      this.setEmpty(true);
      return;
    }
    this.setOrderItems(response.data.items);
    this.setCustomer(response.data.customerData);
    this.setOrder(response.data.orderData);
    this.setInvoice(response.data.invoiceData);
    this.setDelivery(response.data.deliveryData);
    this.setShipping(response.data.shippingData);
    this.setFreight(response.data.freightData);
    this.setOrderValues(response.data.orderValuesData);
    this.getAllOrderStatus(
      this.order,
      this.invoice,
      this.shipping,
      this.delivery,
    );
    this.setLoading(false);
  };

  constructor() {
    makeObservable(this, {
      customer: observable,
      order: observable,
      invoice: observable,
      delivery: observable,
      shipping: observable,
      orderItems: observable,
      actualStatus: observable,
      statusList: observable,
      freight: observable,
      orderValues: observable,
      isLoading: observable,
      isEmpty: observable,
      setEmpty: action,
      setOrder: action,
      getAllOrderStatus: action,
      setCustomer: action,
      setInvoice: action,
      setDelivery: action,
      setShipping: action,
      setOrderItems: action,
      setFreight: action,
      setOrderValues: action,
      setLoading: action,
      getUserData: action,
    });
  }
}

export default OrderDetailStore;
