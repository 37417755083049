import { SnackBar } from '@grupo-sbf/motriz-core';
import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import Header from '@/common/components/Header';
import Menu from '@/common/components/Menu';
import useSellerSelectModal from '@/common/hooks/useSellerSelectModal';
import { Container, Content, PageLayout } from './Layout.styled';
import { Props } from './types';

const Layout = ({ children }: Props) => {
  const { toggleSellerModal, SellerSelectModalComponent } =
    useSellerSelectModal();
  const [isMenuMobileOpen, setIsMenuMobileOpen] = useState(false);
  const [expandedMenu, setExpandedMenu] = useState(true);

  const handleToggleMenuMobile = () => {
    setIsMenuMobileOpen((prevState) => !prevState);
  };

  return (
    <PageLayout>
      <Menu
        toggleSellerModal={toggleSellerModal}
        isMenuOpen={isMenuMobileOpen}
        closeMenu={handleToggleMenuMobile}
        setExpandedMenu={setExpandedMenu}
        expandedMenu={expandedMenu}
      />

      <Container expandedMenu={expandedMenu}>
        <Header openMenu={handleToggleMenuMobile} />
        {SellerSelectModalComponent}
        <Content>{children}</Content>
        <SnackBar />
      </Container>
    </PageLayout>
  );
};

export default inject('AppStore')(observer(Layout));
