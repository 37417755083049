import { Grid } from '@mui/material';
import styled, { css } from 'styled-components';

type RootProps = {
  large: boolean;
};

export const Root = styled(Grid)<RootProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme, large }) => css`
    background: ${theme.color.neutral[100]};
    margin: ${theme.spacing[200]};
    border-radius: ${theme.borderRadius.xlarge};

    padding: ${large ? theme.spacing[0] : theme.spacing[200]} !important;

    ${large
      ? css`
          margin: ${theme.spacing[0]} ${theme.spacing[0]} ${theme.spacing[200]}
            ${theme.spacing[0]} !important;
        `
      : css`
          margin: ${theme.spacing[200]} !important;
        `}
  `};
`;

export const Video = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;

  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.medium};
  `};
`;
